import { Component } from "react";

interface Props {
  recipeTitle: string;
}

interface State {}

class RecipeBanner extends Component<Props, State> {
  showBreadcrumbs = () => {
    return (
      <div className="u-ph--12 u-flex u-align-center">
        <button className="u-main-font--small u-bold u-color-darkgrey ">
          <span className="u-ph--8">
            Feed your family for under $15 /{" "}
            {this.props.recipeTitle ?? "Recipes"}
          </span>
        </button>
      </div>
    );
  };

  render() {
    return (
      <div className="u-w-all u-border--bottom--grey--mobile u-align-center u-relative u-mtauto">
        <div className="u-w-all u-pl--12 u-pr--12 u-relative">
          <img
            src="/images/recipes-banner.jpg"
            className="u-w-all"
            alt="Family feeds for under $15"
          />
        </div>
        <div
          className="u-p--12 u-main-font--banner--desktop u-pv--16 u-color-charcoal u-text-center"
          style={{ lineHeight: "3rem" }}
        >
          We’ve partnered with Beyond Bank Australia to bring you Family Feeds
          for $15. This weeks meal plan is designed from over 6000 specials
          Splitting the ingredients between Coles and Woolworths means you get
          double the savings and the best of both worlds.
        </div>
      </div>
    );
  }
}

export default RecipeBanner;
